import jwtDecode from 'jwt-decode';

type TokenPayload = {
  user: string;
  domain: string;
  noIOS: boolean;
  administrator: boolean;
  'project-administrator': boolean;
  totp: null;
  epoch: null;
  federatedUser: null;
  'cloud-administrator': boolean;
  iat: number;
  exp: number;
};

export const getTokenPayload = (token: string): TokenPayload =>
  jwtDecode<TokenPayload>(token);

export const getTokenExpiration = (token: string): Date => {
  const payload = getTokenPayload(token);
  const earliest = new Date(payload.exp * 1000);
  const expirationTime = new Date(earliest.getTime());

  return expirationTime;
};
