/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import Router from 'next/router';
import type { AxiosRequestHeaders, AxiosError } from 'axios';
import { captureException } from '@sentry/nextjs';

type jsonReq = {
  endpoint: string;
  headers: AxiosRequestHeaders;
};

export enum ERRORS_IDS {
  INVALID_CREDENTIALS = 'InvalidUsernameorPassword',
  NETWORK_ERROR = 'ERR_NETWORK',
  USER_ERROR = 'UserError',
}

export const jsonRequest = (
  path: string,
  domain: string,
  token?: string
): jsonReq => ({
  endpoint: domain ? new URL(path, `https://${domain}`).href : '',
  headers: {
    'Content-Type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  } as AxiosRequestHeaders,
});

export const API = axios.create();

API.interceptors.response.use(
  (res) => {
    try {
      if (res.data?.error) {
        throw new Error(res.data.error);
      }

      if (!res.data && res.status !== 204) {
        throw new Error(`Invalid response, no data`);
      }
    } catch (error) {
      captureException(error, {
        extra: {
          responseData: res.data,
          response_error: res.data?.error ?? null,
          headers: res.headers,
          status: res.status,
        },
        tags: {
          endpoint: res.config.url,
        },
      });

      throw error;
    }

    return res;
  },
  async (error: AxiosError) => {
    const statusCode = error.response?.status;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const errorId = error.response?.data?.errorID as string;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const errorMessage = error.response?.data?.error as string | undefined;
    const errorCode = error.code;
    try {
      if (errorCode === ERRORS_IDS.NETWORK_ERROR) {
        throw new Error(ERRORS_IDS.NETWORK_ERROR);
      }

      if (errorId === ERRORS_IDS.INVALID_CREDENTIALS) {
        throw new Error('Invalid credentials, please check your login details');
      }

      if (statusCode === 401 || statusCode === 403) {
        await Router.push('/reauthenticate');
      }

      if (errorId === ERRORS_IDS.USER_ERROR) {
        throw error.response?.data;
      }

      if (statusCode !== 200) {
        throw new Error(
          errorMessage ?? `Invalid response, status: ${statusCode ?? 'UNKNOWN'}`
        );
      }
    } catch (err) {
      captureException(err, {
        extra: {
          errorCode,
          errorId,
          statusCode,
          data: error.config?.data,
          headers: error.config?.headers,
          message: error.message,
        },
        tags: {
          endpoint: error.config?.url,
          type: 'API_HANDLED',
        },
      });

      throw err;
    }

    captureException(error, {
      extra: {
        errorCode,
        errorId,
        statusCode,
        data: error.config?.data,
        headers: error.config?.headers,
        message: error.message,
      },
      tags: {
        endpoint: error.config?.url,
        type: 'API_UNKNOWN',
      },
    });

    throw error;
  }
);
