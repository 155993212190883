import type { FC, PropsWithChildren } from 'react';

import { Header } from 'components/Header';
import { LoadingPage } from 'components/LoadingPage';

type LayoutProps = PropsWithChildren<{
  isLoading?: boolean;
  isFullscreen?: boolean;
}>;

export const Layout: FC<LayoutProps> = ({
  isLoading,
  isFullscreen,
  children,
}) => {
  if (isLoading) {
    return <LoadingPage />;
  }

  if (isFullscreen) {
    return <>{children}</>;
  }

  return (
    <div className="pb-10">
      <Header />
      {children}
    </div>
  );
};
