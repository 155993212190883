import { notify } from '@corellium/metal';

export const getErrorDescription = (error: unknown): string =>
  error instanceof Error
    ? error.message
    : 'Something went wrong. Please try again.';

export const handleError = (error: unknown): void => {
  const description = getErrorDescription(error);

  notify({
    title: 'Error',
    description,
    variant: 'error',
    'data-testid': 'notification-error',
  });
};
