import type { MutateOptions, UseQueryOptions } from '@tanstack/react-query';

export enum QUERY {
  DOMAINS = 'domains',
  NOTIFICATIONS = 'notifications',
  INCOMING_TRIAL_REQUESTS = 'incomingTrialRequests',
  NODES = 'nodes',
  SENT_TRIAL_REQUEST = 'sentTrialRequest',
  CONFIG = 'config',
  AVAILABLE_TRIALS = 'availableTrials',
}

export type QueryHookOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData>,
  'queryFn' | 'queryKey' | 'context'
>;

export type MutationHookOptions<
  TData = unknown,
  TError = unknown,
  TVariables = unknown
> = Omit<MutateOptions<TData, TError, TVariables>, 'mutationFn'>;

export type ValidationErrorResponse<F = string> = {
  field: F;
  error: string;
  errorID: string;
};

export enum ERROR_TITLES {
  DEFAULT = 'An error occurred',
}

export enum ERROR_DESCRIPTIONS {
  DEFAULT = 'An unexpected error occurred. Please try again later.',
}
