import { LoadingIndicator } from '@corellium/metal';
import type { FC } from 'react';

type LoadingPageProps = {
  label?: string;
};

export const LoadingPage: FC<LoadingPageProps> = ({ label = 'Loading' }) => (
  <div className="flex h-screen flex-1 flex-col items-center justify-center">
    <LoadingIndicator label={label} />
  </div>
);
