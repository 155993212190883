import { useLocalStorageValue } from '@react-hookz/web/cjs';

type useDomainProps = {
  domain: string;
  setDomain: (domain: string) => void;
};

export const useDefaultDomain = (): useDomainProps => {
  const [domain, setDomain] = useLocalStorageValue<string>(
    'atlas-domain',
    'app.corellium.com'
  );

  return {
    domain,
    setDomain,
  };
};
