import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import useUser from 'hooks/useUser';
import { useDefaultDomain } from 'hooks/useDefaultDomain';
import type { NotificationData } from 'types/notification';
import { jsonRequest, API } from 'utils/http';
import { QUERY } from 'types/globals';

type APIResponse = {
  error?: string;
  notifications: NotificationData[];
};

export const useNotifications = (
  options?: UseQueryOptions<NotificationData[], Error>
) => {
  const { user } = useUser();
  const { domain } = useDefaultDomain();
  const urlPath = '/api/v1/cloud-admin/notifications';
  const { endpoint, headers } = jsonRequest(urlPath, domain, user?.token);

  return useQuery<NotificationData[], Error>({
    queryKey: [QUERY.NOTIFICATIONS],
    queryFn: async () => {
      const { data } = await API.get<APIResponse>(endpoint, {
        headers,
      });

      return data.notifications;
    },
    ...options,
  });
};
