import type { AppProps } from 'next/app';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import { Notifications, notify } from '@corellium/metal';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import '@corellium/metal/metal.css';
import 'styles/tailwind.css';
import 'styles/globals.css';
import { Layout } from 'components/Layout';
import useUser from 'hooks/useUser';
import { handleError } from 'utils/handleError';
import { ERROR_DESCRIPTIONS, ERROR_TITLES } from 'types/globals';
import debounce from 'lodash.debounce';
import { ThemeProvider } from 'next-themes';

const debouncedNotify = debounce(notify, 300);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      debouncedNotify({
        title: (query?.meta?.errorTitle as string) ?? ERROR_TITLES.DEFAULT,
        description: error.message ?? ERROR_DESCRIPTIONS.DEFAULT,
        variant: 'error',
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _, __, mutation) => {
      debouncedNotify({
        title: (mutation?.meta?.errorTitle as string) ?? ERROR_TITLES.DEFAULT,
        description:
          error instanceof Error
            ? error.message
            : 'Something went wrong' ?? ERROR_DESCRIPTIONS.DEFAULT,
        variant: 'error',
      });
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Root: FC<AppProps> = ({ Component, pageProps, router }) => {
  const [isMounted, setIsMounted] = useState(false);
  const { user, reauthenticate } = useUser();

  const isFullscreen =
    [
      '/login',
      '/_error',
      '/404',
      '/500',
      '/logout',
      '/reauthenticate',
    ].includes(router.pathname) || router.pathname === '/';
  const isPublicRoute =
    ['/login', '/_error', '/404', '/500'].includes(router.pathname) ||
    router.pathname === '/';

  useEffect(() => {
    setIsMounted(true);

    if (!user?.token) {
      return undefined;
    }

    const interval = setInterval(() => {
      reauthenticate().catch(handleError);
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [user?.token, reauthenticate]);

  useEffect(() => {
    if (!isPublicRoute && !user?.token) {
      router.push('/login').catch(handleError);
    }
  }, [user?.token, router, isPublicRoute]);

  if (!isMounted) {
    return null;
  }

  return (
    <ThemeProvider
      defaultTheme="light"
      storageKey="@corellium/cloud-admin:theme"
      attribute="class"
    >
      <Layout
        isLoading={!user?.token && !isPublicRoute}
        isFullscreen={isFullscreen}
      >
        <Component {...pageProps} />
      </Layout>
    </ThemeProvider>
  );
};

const App: FC<AppProps> = (props) => (
  <>
    <Head>
      <title>Cloud Admin</title>
      <meta name="robots" content="noindex" />
    </Head>
    <QueryClientProvider client={queryClient}>
      <Root {...props} />
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
    <Notifications position="bottom-right" />
  </>
);

export default App;
